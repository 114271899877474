import Cookies from "universal-cookie";
import Axios from "../helper/axiosHelper";
import { BASEURL } from "../constants/constants";

const axios = new Axios();
const cookies = new Cookies();
const baseUrl = BASEURL;

class PatientFamilyService {
  getPatients() {
    const option = {
      url: `${baseUrl}/patient`,
    };

    return axios.get(option);
  }
  createPatientfamilymember(data) {
    const option = {
      url: `${baseUrl}/patientfamily`,
      data,
    };
    return axios.post(option);
  }
  getPatientfamilymemberDetail(data) {
    const option = {
      url: `${baseUrl}/patientfamilydetailbyid`,
      data,
    };
    return axios.post(option);
  }
  updatePatientfamilymemberDetail(data) {
    const option = {
      url: `${baseUrl}/patientfamilydetailupdate`,
      data,
    };
    return axios.post(option);
  }
  deletePatientFamily(id) {
    const option = {
      url: `${baseUrl}/deletepatient_family/${id}`,
    };

    return axios.get(option);
  }
  deletePatient(id) {
    const option = {
      url: `${baseUrl}/patient/${id}`,
    };

    return axios.delete(option);
  }

  getPatientById(id) {
    const option = {
      url: `${baseUrl}/patient/${id}`,
    };

    return axios.get(option);
  }
  getPatientListByDoctorId(id) {
    const option = {
      url: `${baseUrl}/getpatientlistbydoctor/${id}`,
    };

    return axios.get(option);
  }

  updatePatient(data) {
    const option = {
      url: `${baseUrl}/patient`,
      data,
    };

    return axios.put(option);
  }

  getPatientDataForFlow() {
    const option = {
      url: `${baseUrl}/patientdata`,
    };

    return axios.get(option);
  }
    getQuestionList()
  {
    const option = {
      url: `${baseUrl}/initialhealthquestiont`
    };
    return axios.get(option)
  }
  getQuestionListbyPatientId(id)
  {
    console.log("get pt data");
    const x = id ? id: "1234"
    const option = {
      url: `${baseUrl}/initia_lhealth_question/${x}`
    };
    return axios.get(option)
  }
  getExerciseByPatient(id)
  {
    let x =id? id: "12334"
    console.log("called get exercise ");
    const option = {
      url: `${baseUrl}/exercise/patient/${x}`
    }
    return axios.get(option)
  }
  getAllExercise()
  {
    const option = {
      url: `${baseUrl}/exercise/global`
    }
    return axios.get(option)
  }
  createPatientExercise(data)
  {
    
  const option = {
    url: `${baseUrl}/exercise/patient` ,
    data: data
  }
  return axios.post(option)
  
  }
  postPatientQuestionAnswer(data1) {
    console.log("Qna " , data1);
    const option = {
      url: `${baseUrl}/initia_lhealth_question`,
      data: { assignquestion: data1 },
    };

    return axios.post(option);
  }
  postFirstPatientFlow(data, id) {
    const option = {
      url: `${baseUrl}/patientdata/${id}`,
      data,
    };

    return axios.post(option);
  }

  createPatientDailyLog(text, id, day) {
    const option = {
      url: `${baseUrl}/createpatientdailylog`,
      data: { text, id, day },
    };

    return axios.post(option);
  }

  addKneeQs(main, question) {
    const option = {
      url: `${baseUrl}/knessQs`,
      data: { main, question },
    };

    return axios.post(option);
  }

  submitLogs(logs) {
    console.log("logs ", logs);
    const option = {
      url: `${baseUrl}/postSurgeryLogs`,
      data: logs,
    };

    return axios.post(option);
  }
  uploadImage(data) {
    const option = {
      url: `${baseUrl}/uploadImage`,
      data,
    };
    console.log(option);
    return axios.post(option);
  }
  getPatientByCareId() {
    const option = {
      url: `${baseUrl}/patientdataByCare`,
    };

    return axios.get(option);
  }

  updatepatientActive(data) {
    const option = {
      url: `${baseUrl}/patientActive`,
      data,
    };

    return axios.put(option);
  }

}

const patientFamilyService = new PatientFamilyService();
export default patientFamilyService;
