import Cookies from "universal-cookie";
import axios from "axios";
 
const cookies = new Cookies();
export default class Axios {
  get(options) {
    options.method = "get";
    options.headers = this.getHeaders();
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
        
          if(response.status===200 && response.data.code===190){
            window.location.replace(`${window.location.origin}${response.data.redireturl}`)
            // navigate(`${response.data.redireturl}`)
          }
          else{
            resolve(response.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
 
  post(options) {
    const config = {
      method: "POST",
      url: options.url,
      data: options.data,
      headers: {
        'Content-Type': 'application/json',
        ...this.getHeaders()
      },
      withCredentials: true, //for CORS
      validateStatus: status => status >= 200 && status < 503 //to ensure proper CORS handling
    };
    
    return axios(config)
    .then(response => {
      console.log('Response:', response);
      if(response.status === 200 && response.data.code === 190) {
        window.location.replace(`${window.location.origin}${response.data.redireturl}`);
      }
      return response;
    })
    .catch(error => {
      console.error('API Error:', {
        message: error.message,
        response: error.response,
        config: error.config
      });
      throw error;
    });
  }

  upload(options) {
    options.method = "POST";
    options.headers = this.getHeaders();
    options.headers["content-type"] = "multipart/form-data";
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          if(response.status===200 && response.data.code===190){
            window.location.replace(`${window.location.origin}${response.data.redireturl}`)
            // navigate(`${response.data.redireturl}`)
          }
          else{
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
 
  patch(options) {
    options.method = "PATCH";
    options.headers = this.getHeaders();
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          if(response.status===200 && response.data.code===190){
             window.location.replace(`${window.location.origin}${response.data.redireturl}`)
            // navigate(`${response.data.redireturl}`)
          }
          else{
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
 
  put(options) {
    options.method = "PUT";
    options.headers = this.getHeaders();
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          if(response.status===200 && response.data.code===190){
             window.location.replace(`${window.location.origin}${response.data.redireturl}`)
            // navigate(`${response.data.redireturl}`)
          }
          else{
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
 
  delete(options) {
    options.method = "DELETE";
    options.headers = this.getHeaders();
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          if(response.status===200 && response.data.code===190){
            window.location.replace(`${window.location.origin}${response.data.redireturl}`)
            // navigate(`${response.data.redireturl}`)
          }
          else{
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
 
  getHeaders() {
    const token = cookies.get("truss_session");
    const headers = {
      'Accept': 'application/json'
    };
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }   
    return headers;
  }
}